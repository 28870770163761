var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: {
          title: "回收标准",
          isbackPath: true,
          path: "person",
          "bg-color": "#39394D"
        }
      }),
      !_vm.loading
        ? _c("img", {
            attrs: {
              src: require("@/assets/images/recover_short_black.png"),
              alt: ""
            }
          })
        : _vm._e(),
      _vm._m(0),
      _vm.loading
        ? _c(
            "center",
            { attrs: { id: "loadingbox" } },
            [_c("van-loading", { attrs: { size: "70px" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main_info" }, [
      _c("p", [_vm._v("客服热线：400-850-6718")]),
      _c("br"),
      _c("p", [_vm._v("地址：深圳市罗湖区贝丽北路99号水贝国际中心37楼")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }